import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

type AuthFormTabsProps = {
  readonly selectedTab: number;
  readonly setSelectedTab: (data: number) => void;
};

const Container = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 4rem;
`;

const BlankSpace = styled('div')`
  flex: 1;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const TabsContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1;
`;

const TabButton = styled('button')<{ isActive: boolean }>`
  color: var(--Pallets-Neutral-200, #adadad);

  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */

  height: 3rem;
  background: transparent;
  border: 0;
  border-bottom: 4px solid;
  border-color: #ebebeb;
  cursor: pointer;
  flex: 1;

  ${({ isActive }) =>
    isActive
      ? `
        border-image: linear-gradient(274.41deg, #6a79c1 0%, #f081b8 100%) 1;
        color: var(--Pallets-Neutral-700, #484848);
      `
      : ''}

  :hover {
    border-image: linear-gradient(274.41deg, #6a79c1 0%, #f081b8 100%) 1;
    background: transparent;
    color: var(--Pallets-Neutral-700, #484848);
  }
`;

function AuthFormTabs({ selectedTab, setSelectedTab }: AuthFormTabsProps) {
  const { t } = useTranslation();

  const handleTabChange = (index: number) => {
    setSelectedTab(index);
  };

  return (
    <Container>
      <BlankSpace />

      <TabsContainer>
        <TabButton
          isActive={selectedTab === 0}
          onClick={() => handleTabChange(0)}
        >
          {t('register.buttons.sign-up')}
        </TabButton>

        <TabButton
          isActive={selectedTab === 1}
          onClick={() => handleTabChange(1)}
        >
          {t('register.buttons.sign-in')}
        </TabButton>
      </TabsContainer>
    </Container>
  );
}

export default AuthFormTabs;
