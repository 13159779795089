import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { Grid } from '@devstart/react-bootstrap';
import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby-link';
import { Spacer } from '@components/helpers';
import AuthFormTabs from '@components/authentication/auth-form-tabs';
import createRedirect from '@components/create-redirect';
import SignUp from '@components/sign-up/sign-up';
import SignIn from '@components/sign-in/sign-in';
import ModalCtaUnlockAccount from '@components/modals/ModalCtaUnlockAccount';
import {
  isModalGoToUnlockPageOpenSelector,
  isSignedInSelector
} from '../redux/selectors';
import { closeModalGoToUnlockPage } from '../redux/actions';

import './index.css';

const mapStateToProps = createSelector(
  isSignedInSelector,
  isModalGoToUnlockPageOpenSelector,
  (isSignedIn: boolean, isModalGoToUnlockPageOpen: boolean) => ({
    isSignedIn,
    isModalGoToUnlockPageOpen
  })
);

const mapDispatchToProps = {
  closeModalGoToUnlockPage
};

export interface IndexProps {
  readonly isSignedIn?: boolean;
  readonly isModalGoToUnlockPageOpen: boolean;
  readonly closeModalGoToUnlockPage: () => void;
}

interface UTMObject {
  [key: string]: string | null;
}

const RedirectLearn = createRedirect('/learn');

function IndexPage({
  isSignedIn,
  isModalGoToUnlockPageOpen,
  closeModalGoToUnlockPage
}: IndexProps): JSX.Element {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(0);

  const params = new URLSearchParams(location.search);
  const utmSource = params.get('utm_source');

  if (utmSource !== null) {
    const utmObject: UTMObject = {};
    params.forEach((value, key) => {
      utmObject[key] = value;
    });

    localStorage.setItem('devStart:utm', JSON.stringify(utmObject));
  }

  if (isSignedIn) {
    return <RedirectLearn />;
  }

  return (
    <>
      <Helmet title={`${t('register.title')} | DEVstart`} />
      <Grid className='index-container'>
        <Spacer size='large' />

        <AuthFormTabs
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <Spacer size='medium' />

        <div className='sign-container'>
          {selectedTab === 0 ? <SignUp /> : <SignIn />}
        </div>
      </Grid>

      <ModalCtaUnlockAccount
        show={isModalGoToUnlockPageOpen}
        onHide={() => closeModalGoToUnlockPage()}
        handleClick={() => {
          closeModalGoToUnlockPage();
          void navigate('/unlock-account');
        }}
      />
    </>
  );
}

IndexPage.displayName = 'IndexPage';

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage);
