import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectSignUpLoading } from '../../redux/selectors';
import { signUpLocal } from '../../redux/actions';
import { Spacer } from '../helpers';
import { apiLocation } from '../../../../config/env.json';

import Input from '../ui/Input';
import Button from '../ui/Button';
import { ButtonTypes } from '../ui/Button/button-types';
import InputPassword from '../ui/input-password';

import GoogleIcon from '../../assets/icons/google-icon';
import CheckedHome from '../../assets/icons/checked-home';
import {
  formatPhoneValue,
  isCPFValid,
  isPasswordValid,
  isEmail
} from '../../utils/data-complement/validate-inputs';
import Marker from '../../assets/icons/marker';

type FormValues = {
  name: string;
  phone: string;
  cpf: string;
  email: string;
  password: string;
};

const mapStateToProps = createSelector(
  selectSignUpLoading,
  (signUpLoading: boolean) => ({
    signUpLoading
  })
);

const mapDispatchToProps = {
  signUpLocal
};

function SignUp({
  signUpLocal,
  signUpLoading
}: {
  readonly signUpLocal: (formValues: FormValues) => void;
  readonly signUpLoading: boolean;
}) {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = useForm<FormValues>({
    defaultValues: {
      name: '',
      phone: '',
      cpf: '',
      email: '',
      password: ''
    }
  });

  const onSubmit = (data: FormValues): void => {
    signUpLocal(data);
  };

  return (
    <>
      <section className='info-section'>
        <p id='main-title'>{t('register.headings.sign-up-title')}</p>
        <Spacer size='large' />
        <div id='secundary-title-container'>
          <span className='secondary-title'>
            {t('register.headings.sign-up-subtitle01')}
          </span>
          <span className='secondary-title' id='text-marker'>
            <Marker id='marker' />
            {t('register.headings.sign-up-subtitle02')}
          </span>
          <span className='secondary-title'>
            {t('register.headings.sign-up-subtitle03')}
          </span>
        </div>
        <Spacer size='large' />
        {[1, 2, 3].map(i => (
          <div key={i} className='register-benefits'>
            <CheckedHome />
            <p>{t(`register.headings.sign-up-benefits${i}`)}</p>
          </div>
        ))}
      </section>

      <section className='register-section'>
        <a className='google-container' href={`${apiLocation}/signin-google`}>
          <GoogleIcon className='google-icon' />
          <p>{t('register.buttons.sign-up-google')}</p>
        </a>
        <Spacer size='medium' />
        <p id='google-or-local'>Ou</p>
        <Spacer size='medium' />
        <form onSubmit={handleSubmit(onSubmit) as () => void}>
          <Input
            {...register('name', { required: 'Nome completo é obrigatório' })}
            placeholder='Nome completo'
            hasError={!!errors.name}
            messageError={errors.name?.message}
          />
          <Input
            {...register('phone', {
              validate: value =>
                formatPhoneValue(value) ||
                'Verifique o número de telefone e tente novamente.'
            })}
            placeholder='Telefone'
            hasError={!!errors.phone}
            messageError={errors.phone?.message}
            hasMask={true}
            maskValue='(99) 99999-9999'
          />
          <Input
            {...register('cpf', {
              validate: value =>
                isCPFValid(value) ||
                'Verifique o número do CPF e tente novamente.'
            })}
            placeholder='CPF'
            hasError={!!errors.cpf}
            messageError={errors.cpf?.message}
            hasMask={true}
            maskValue='999.999.999-99'
          />
          <Input
            {...register('email', {
              required: 'Email é obrigatório',
              validate: value =>
                isEmail(value) || 'Verifique o e-mail e tente novamente.'
            })}
            type='email'
            placeholder='Email'
            hasError={!!errors.email}
            messageError={errors.email?.message}
          />
          <Controller
            control={control}
            name='password'
            rules={{
              validate: value =>
                isPasswordValid(value) ||
                'A senha deve ter pelo menos 8 caracteres.'
            }}
            render={({ field: { onChange, value } }) => (
              <InputPassword
                value={value}
                onChange={onChange}
                placeholder='Senha'
                hasError={!!errors.password}
                messageError={errors.password?.message}
              />
            )}
          />
          <div className='form-btn'>
            <Button
              type='submit'
              buttonType={ButtonTypes.Primary}
              style={{ height: '48px', width: '100%' }}
              isLoading={signUpLoading}
            >
              {t('register.buttons.new-account')}
            </Button>
          </div>
        </form>
        <Spacer size='medium' />
        <p id='info-terms'>{t('register.headings.sign-up-accept-terms')}</p>
      </section>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
