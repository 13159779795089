import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby';
import { selectSignInLoading, userSelector } from '../../redux/selectors';
import { signInLocal } from '../../redux/actions';
import { apiLocation } from '../../../../config/env.json';

import { User } from '../../redux/prop-types';
import GoogleIcon from '../../assets/icons/google-icon';
import Button from '../ui/Button';
import { ButtonTypes } from '../ui/Button/button-types';
import Input from '../ui/Input';
import { Spacer } from '../helpers';
import InputPassword from '../ui/input-password';
import { isEmail } from '../../utils/data-complement/validate-inputs';

type FormValues = {
  email: string;
  password: string;
};

const mapStateToProps = createSelector(
  userSelector,
  selectSignInLoading,
  (user: User, signInLoading: boolean) => ({
    user,
    signInLoading
  })
);

const mapDispatchToProps = {
  signInLocal
};

function SignIn({
  signInLocal,
  signInLoading
}: {
  readonly signInLocal: (formValues: FormValues) => void;
  readonly signInLoading: boolean;
}) {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = useForm<FormValues>({
    defaultValues: {
      email: '',
      password: ''
    }
  });

  const onSubmit = (data: FormValues): void => {
    signInLocal(data);
  };

  return (
    <>
      <section className='info-section'>
        <p id='main-title'>{t('register.headings.sign-in-heading1')}</p>
        <Spacer size='medium' />
        <p id='second-title-sign-in'>
          {t('register.headings.sign-in-heading2')}
        </p>
      </section>

      <section className='register-section'>
        <a className='google-container' href={`${apiLocation}/signin-google`}>
          <GoogleIcon className='google-icon' />
          <p>{t('register.buttons.sign-in-google')}</p>
        </a>
        <Spacer size='medium' />
        <p id='google-or-local'>Ou</p>
        <Spacer size='medium' />
        <form onSubmit={handleSubmit(onSubmit) as () => void}>
          <Input
            {...register('email', {
              required: 'Email é obrigatório',
              validate: value => isEmail(value) || 'Email inválido'
            })}
            type='email'
            placeholder='Email'
            hasError={!!errors.email}
            messageError={errors.email?.message}
          />
          <Controller
            control={control}
            name='password'
            rules={{ required: 'Senha é obrigatória' }}
            render={({ field: { onChange, value } }) => (
              <InputPassword
                value={value}
                onChange={onChange}
                placeholder='Senha'
                hasError={!!errors.password}
                messageError={errors.password?.message}
              />
            )}
          />
          <div className='form-btn'>
            <Button
              type='submit'
              buttonType={ButtonTypes.Primary}
              style={{ height: '48px', width: '100%' }}
              isLoading={signInLoading}
            >
              {t('register.buttons.log-into-account')}
            </Button>
          </div>
        </form>
        <Spacer size='medium' />
        <div id='forgot-password-container'>
          <Link id='forgot-password' to='/forgot-password'>
            <p>{t('register.buttons.forgot-password')}</p>
          </Link>
        </div>
        <Spacer size='medium' />
        <p id='info-terms'>{t('register.headings.sign-up-accept-terms')}</p>
      </section>
    </>
  );
}

SignIn.displayName = 'SignInPage';

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
